<template>
  <div
    class="page flex-col"
    ref="page"
    :style="
      hostname == 'demo.daping.cn'
        ? 'height: calc(100vh - 120rem)'
        : 'height: calc(100vh - 70rem)'
    "
    v-infinite-scroll="videoListLoad"
    infinite-scroll-distance="1"
    infinite-scroll-immediate="false"
  >
    <div class="flex-row justify-center">
      <menuTabs style="margin-right: 26rem" />
      <div class="right-view flex-col">
        <div class="adList flex-row flex-wrap" v-if="videoList.length">
          <div
            class="adList-item flex-col"
            v-for="(item, index) in videoList"
            :key="index"
            @click.stop="handleClickSlide(item)"
          >
            <div class="item-top flex-col">
              <div
                class="videoThumbnailUrl flex-col justify-center"
                @mouseenter="temPlay(index)"
                @mouseleave="temPause(index)"
              >
                <video
                  oncontextmenu="return false;"
                  :id="'temVideo' + index"
                  class="video"
                  :poster="item.videoThumbnailUrl"
                  loop
                  muted
                  preload="auto"
                >
                  <source :src="item.videoUrl" type="video/mp4" />
                </video>
                <img
                  v-if="item.muted"
                  @click.stop="changeMute(item, index)"
                  class="icon"
                  src="@/assets/img/mute.png"
                />
                <img
                  v-else
                  class="icon"
                  @click.stop="changeMute(item, index)"
                  src="@/assets/img/noMute.png"
                />
              </div>
              <div class="desc1">
                <span
                  >{{ item.resolvingPower }}像素，宽高比
                  {{ item.aspectRatio }}</span
                >
              </div>
              <div class="desc2">
                <span> 上传/创建于 {{ item.updateTime | dateFormat }}</span>
              </div>
            </div>
            <div class="btn-view flex-row align-center">
              <div class="btn1" @click.stop="launch(item)">投放广告</div>
              <div class="btn2" @click.stop="downloadFile(item)">下载</div>
              <div class="btn3" @click.stop="delVideo(item, index)">删除</div>
            </div>
          </div>
        </div>
        <div v-else class="noData flex-col justify-center align-center">
          <span>暂无视频记录，可投放广告生成新视频</span>
          <div
            class="launch-btn flex-col"
            id="primary-btn"
            @click="onClick_create"
          >
            去投放广告
          </div>
        </div>
        <div class="explain-view flex-col">
          <span
            >*
            您创建广告订单时上传的视频，使用图片合成的视频，将自动保存到这里。</span
          >
          <!-- <span
          >*
          我们最多只能帮您保存100个视频，总容量不超过100GB，如果超过，我们会自动删除最旧的视频。</span
        > -->
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-backtop target=".page" :visibility-height="350">
      <div class="backtop flex-row justify-center align-center">
        <svg-icon class="gotop-svg" iconClass="gotop"></svg-icon>
      </div>
    </el-backtop>
    <pre-video-dialog
      ref="refPreVideo"
      :videoData="preVideoData"
    ></pre-video-dialog>
    <del-video-dialog
      ref="refDelVideo"
      type="video"
      @onOkDel="onOkDel"
    ></del-video-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import menuTabs from "@/components/menuTabs.vue";
import { listMyVideo, deleteMyVideo } from "@/api/request";
import Footer from "@/layout/components/footer.vue";
import preVideoDialog from "@/components/preVideoDialog.vue";
import delVideoDialog from "./components/delVideoDialog.vue";

export default {
  components: {
    menuTabs,
    Footer,
    preVideoDialog,
    delVideoDialog,
  },
  data() {
    return {
      videoList: [],
      allAdHistoryList: [],
      preVideoData: {}, // 预览视频数据
      currentDelVideoUrl: "", // 当前要删除的视频路径
      currentDelVideoIndex: 0, // 当前要删除的视频索引
      currentPages: 0,
      pageParams: {
        is_design: false,
        pageLimit: {
          limit: 12,
          page: 1,
        },
      },
    };
  },
  created() {
    this.getListMyVideo();
  },
  mounted() {},
  computed: {
    hostname() {
      return window.location.hostname;
    },
  },
  methods: {
    // 获取历史投放
    getListMyVideo() {
      listMyVideo(this.pageParams)
        .then((res) => {
          this.currentPages = res.pageResult.pages;
          if (this.pageParams.pageLimit.page == 1) {
            this.videoList = res.videoList;
          } else {
            this.videoList = [...this.videoList, ...res.videoList];
          }
          if (this.videoList.length) {
            this.$nextTick(() => {
              this.videoList.forEach((item, index) => {
                item.muted = true;
                // 计算视频的分辨率和宽高比
                const videoElement = document.getElementById(
                  `temVideo${index}`
                );
                videoElement.addEventListener("loadedmetadata", () => {
                  // console.log(videoElement.videoWidth, videoElement.videoHeight);
                  const maxNumber = this.maxNumber(
                    videoElement.videoWidth,
                    videoElement.videoHeight
                  ); // 宽和高的最大公约数
                  item.resolvingPower = `${videoElement.videoWidth} × ${videoElement.videoHeight}`;
                  item.aspectRatio = `${
                    videoElement.videoWidth / maxNumber
                  } : ${videoElement.videoHeight / maxNumber}`;
                  // 强制更新组件
                  this.$forceUpdate();
                });
              });
            });
          }
        })
        .catch(() => {
          if (this.pageParams.pageLimit.page > 1) {
            this.pageParams.pageLimit.page--;
          }
        });
    },
    // 计算最大公约数
    maxNumber(a, b) {
      if (a > 0 && b > 0) {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      }
      return 1;
    },
    // 点击单个历史投放广告
    handleClickSlide(item) {
      this.preVideoData = {
        dialogUrl: `${item.videoUrl}?${new Date().getTime()}`,
        muted: item.muted,
      };
      setTimeout(() => {
        this.$refs.refPreVideo.openDialog();
      }, 100);
    },
    temPlay(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // console.log("视频时长:", video.duration);
      // 播放视频
      video.play();
    },
    temPause(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // 停止播放
      video.pause();
      setTimeout(() => {
        video.load();
      }, 10);
    },
    // 静音/取消静音
    changeMute(item, index) {
      const videoElement = document.getElementById(`temVideo${index}`);
      videoElement.muted = !videoElement.muted;
      item.muted = videoElement.muted;
      // 强制更新组件
      this.$forceUpdate();
    },
    launch(item) {
      this.$router.push({
        name: "AddLaunchInfo",
        query: {
          videoUrl: item.videoUrl,
          realVideoDuration: item.videoDuration,
        },
      });
    },
    downloadFile(item) {
      fetch(item.videoUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          const objectUrl = window.URL.createObjectURL(blob);
          a.download = item.designTitle || "我的视频";
          a.href = objectUrl;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        });
    },
    delVideo(item, index) {
      this.currentDelVideoUrl = item.videoUrl;
      this.currentDelVideoIndex = index;
      this.$refs.refDelVideo.openDialog();
    },
    onOkDel() {
      deleteMyVideo({
        video_url: this.currentDelVideoUrl,
      }).then(() => {
        this.$message({
          type: "success",
          center: true,
          message: "删除成功",
        });
        this.videoList = [];
        this.pageParams.pageLimit.page = 1;
        this.getListMyVideo();
        // this.videoList.splice(this.currentDelVideoIndex, 1); // 手动删除页面上的该视频
      });
    },
    videoListLoad() {
      if (this.pageParams.pageLimit.page < this.currentPages) {
        this.pageParams.pageLimit.page++;
        console.log(
          "触发了下拉到底，进行分页请求，请求页码为",
          this.pageParams.pageLimit.page
        );
        this.getListMyVideo();
      }
    },
    onClick_create() {
      this.$canvaUtil.onClickCreate();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  background-color: #fff;
  padding-top: 60rem;
  overflow-y: auto;
  margin: 0 auto;
  .el-backtop {
    width: 50rem;
    height: 50rem;
    right: 40rem !important;
    bottom: 240rem !important;
    box-shadow: none;
    background: #fff;
    border: 1rem solid #dedede;
    .el-icon-arrow-up {
      transition: all 0.3s;
      color: #42b6fb;
    }
    .gotop-svg {
      width: 48rem;
      height: 48rem;
      color: #42b6fb;
    }
    &:hover {
      background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
      border: none;
      .el-icon-arrow-up {
        color: #fff;
      }
      .gotop-svg {
        color: #fff;
      }
    }
  }
  .right-view {
    width: 1060rem;
    margin-bottom: 60rem;
    min-height: calc(100vh - 196rem);
    .adList {
      width: 100%;
      .adList-item {
        width: 322rem;
        margin: 0 47rem 40rem 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .item-top {
          width: 100%;
          height: 256rem;
          background: #f6f7f9;
          border-radius: 8rem;
          overflow: hidden;
          .videoThumbnailUrl {
            z-index: 94;
            width: 100%;
            min-height: 180rem;
            background-color: #333333;
            position: relative;
            cursor: pointer;

            .video {
              width: 100%;
              max-height: 187rem;
              // border-radius: 6rem 6rem 0 0;
              object-fit: contain;
            }
            .icon {
              width: 28rem;
              height: 28rem;
              position: absolute;
              bottom: 15rem;
              right: 15rem;
              display: none;
            }
            &:hover {
              .icon {
                display: block;
              }
            }
          }

          .desc1 {
            margin: 12rem 0 0 12rem;
            span {
              font-size: 14rem;
              font-weight: 500;
              font-family: PingFang-SC-Medium, PingFang-SC;
              line-height: 20rem;
              color: #252927;
            }
          }

          .desc2 {
            margin: 5rem 0 0 12rem;

            span {
              font-size: 14rem;
              font-family: PingFang-SC-Medium, PingFang-SC;
              font-weight: 500;
              line-height: 20rem;
              color: #969898;
            }
          }
        }
        .btn-view {
          margin-top: 15rem;
          .btn1,
          .btn2,
          .btn3 {
            width: 94rem;
            height: 32rem;
            border-radius: 8rem;
            text-align: center;
            line-height: 32rem;
            font-size: 16rem;
            cursor: pointer;
          }
          .btn1 {
            background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
            color: #ffffff;
          }
          .btn2 {
            border: 1px solid #32c3f6;
            color: #2bb3e3;
            margin-left: 20rem;
          }
          .btn3 {
            border: 1px solid #f52f3e;
            color: #f52f3e;
            margin-left: 20rem;
          }
        }
      }
    }
    .noData {
      width: 1060rem;
      height: 214rem;
      background: #f8f8f8;
      border-radius: 8rem;
      margin-bottom: 40rem;
      span {
        font-size: 22rem;
        color: #252927;
        line-height: 30rem;
      }
      .launch-btn {
        width: 168rem;
        height: 44rem;
        text-align: center;
        line-height: 44rem;
        border-radius: 25rem;
        cursor: pointer;
        transition: all 0.3s;
        margin-top: 40rem;
        font-size: 18rem !important;
        color: #ffffff;
      }
    }
    .explain-view {
      width: 1060rem;
      height: 54rem;
      background: #f8f8f8;
      border-radius: 8rem;
      padding-left: 20rem;
      padding-top: 16rem;

      span {
        font-size: 16rem;
        color: #a1a3a2;
        line-height: 22rem;
        &:nth-child(2) {
          margin-top: 14rem;
        }
      }
    }
  }
}
</style>
